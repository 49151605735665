#main .tab-content > .active {
  // When .was-validated is removed from the CSS,
  // some selectors are grouped by cssnano, altering their priority.
  display: block;
}

.intake-form {
  margin-bottom: 4rem;

  @include media-breakpoint-up(md) {
    padding-left: 2rem;
  }

  .custom-checkbox .custom-control-label::before,
  .custom-checkbox .custom-control-label::after {
    // before is the box, after is the checkmark.
    // Move the box slightly more down.
    top: 0.35rem;
  }

  .custom-checkbox {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    label {
      flex: 1 0;
    }

    small {
      flex: 1 0 100%;
    }

    .asteriskField {
      display: none;
    }
  }


  h3 {
    margin-top: 0;
  }

  .tab-anamnesis {
    .form-group {
      margin-top: 1.5rem;
    }
  }

  label .form-text {
    margin-top: 0;
  }

  .card-body .form-group {
    // Make distance between all fields smaller
    margin-bottom: 0.5rem;
  }

  .form-actions {
    margin-top: 2rem;
  }
}
