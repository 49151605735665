.videoplayer {
  position: relative;
  margin: 2rem 0;

  .shape {
    transition: fill .1s cubic-bezier(0.4, 0, 1, 1), fill-opacity .1s cubic-bezier(0.4, 0, 1, 1);
    fill: #212121;
    fill-opacity: .8;
  }

  a:hover .shape {
    transition: fill .1s cubic-bezier(0, 0, 0.2, 1), fill-opacity .1s cubic-bezier(0, 0, 0.2, 1);
    fill: #f00;
    fill-opacity: 1;
  }
}

.video-responsive {
  overflow: hidden;
  position: relative;

  &.scale-4x3 {
    // 4:3 aspect ratio
    padding-top: 75%;
  }

  &.scale-16x9 {
    // 16:9 aspect ratio
    padding-top: 56.25%;
  }

  > :first-child {
    // iframe or 'a'
    position: absolute;
    border: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.video-frame {
  background-color: #000;
  position: relative;
}

article img.video-thumbnail {
  border-radius: 0;  // reset from typography.scss
}

.video-title {
  position: absolute;
  top: 12px;
  left: 12px;
  right: 38px;
  white-space: nowrap;
  word-wrap: normal;

  img {
    border-radius: 50%;
    margin: 0 10px 1px 0;  // aligns text
  }

  a {
    color: #eee;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    font: 1.3 18px Roboto, Arial, Helvetica, sans-serif;
    font-weight: 500;

    display: inline-block;
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      color: #fff;
    }
  }

  > * {
    vertical-align: middle;
  }
}

.video-caption {
  text-align: center;
  font-style: italic;
  margin: .5rem 0;
}

.play-button {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1.5em;
  margin: -0.75em auto 0 auto;

  text-align: center;
  font: 48px/1.5 sans-serif;
  color: #fff;
  text-shadow: 0 0 0.5em #000;
}

.video-title-box {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 110px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==);
  background-repeat: repeat-x;
}

.watch-on-yt {
  position: absolute;
  bottom: 5px;
  height: 47px;
  padding: 16px 12px;
  font: 16px/16px Roboto, Arial, Helvetica, sans-serif;
  font-weight: 500;

  color: #fff;
  background: rgba(23,23,23,0.8);
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;

  &:hover {
    color: #fff;
  }

  svg {
    margin-left: 5px;
    vertical-align: top;
  }
}
