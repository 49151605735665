// These mixins make it easy to write media queries for device specific CSS.
// Usage:
//
//  @include media-phone-only { sass rules }
//

@function spacer($index) {
    @return map-get($spacers, $index);
}

@function image-url($path) {
    @return url("#{$image-url-path}#{$path}");
}

@function font-url($path) {
    @return url("#{$fonts-url-path}#{$path}");
}

@mixin font-smoothing($value: on) {
    @if $value == on {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    @else {
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: auto;
    }
}

@mixin media-xs-down {
  // Phones portrait
  @include media-breakpoint-down(xs) { @content; }
}

@mixin media-sm-down {
  // Phones
  @include media-breakpoint-down(sm) { @content; }
}

@mixin media-sm-only {
  // Phone landscape
  @include media-breakpoint-only(sm) { @content; }
}

@mixin media-sm-up {
  // Phone landscale, tablet, desktop
  @include media-breakpoint-up(sm) { @content; }
}

@mixin media-md-down {
  // Tablet and phones
  @include media-breakpoint-down(md) { @content; }
}

@mixin media-md-up {
  // Tabled and desktop
  @include media-breakpoint-up(md) { @content; }
}

@mixin media-md-only {
  // Tablet only
  @include media-breakpoint-only(md) { @content; }
}

@mixin media-lg-only {
  // Large desktop only
  @include media-breakpoint-only(lg) { @content; }
}

@mixin media-lg-up {
  // Desktop only
  // NOTE: this is disabled in _bootstrap_overrides, the container has a max width
  @include media-breakpoint-up(lg) { @content; }
}

@mixin media-xl-only {
  // Large desktop only
  @include media-breakpoint-only(xl) { @content; }
}

@mixin media-2x {
  @media
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (   min--moz-device-pixel-ratio: 2),
  only screen and (     -o-min-device-pixel-ratio: 2/1),
  only screen and (        min-device-pixel-ratio: 2),
  only screen and (                min-resolution: 192dpi),
  only screen and (                min-resolution: 2dppx) {
    @content;
  }
}
