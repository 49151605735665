.newsletter-signup {
  position: relative;

  > div,
  > form {
    max-width: 25rem;
  }

  .flex-group {
    // Group in single combined row
    display: flex;
    padding: 1rem 0;  // also space for error messages
  }

  // input takes all space
  input { flex: 1 1 100%; @include border-right-radius(0); }
  button { @include border-left-radius(0); }

  .signup-success {
    // alert-success effect
    padding: 0.4rem 0.3rem;
    margin: 0.1rem 0;
    text-align: center;
    font-size: 85%;
    background-color: $primary;
    border: 1px solid theme-color-level($level: -1);
    border-radius: 4px;
    color: #fff;

    p { margin: 0; }
  }

  .invalid-tooltip {
    margin-top: 0;
    display: block;
  }
}

.newsletter-signup-inline {
  margin: -1rem 0 1rem 0;
}
