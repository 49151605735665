.iconteaser {
  margin: spacer(3) 0;

  > img,
  .icon-link {
    width: 80px;  // reduce Cumulative Layout Shift (CLS)
    height: 80px;
    box-sizing: content-box;  // extra padding is added inside box.
  }

  .media-body {
    margin-left: spacer(3);
  }

  #homepage & {
    margin: spacer(4) 0;
  }
}
