@charset "utf-8";

// ---- extra resets besides bootstrap/normalize

:focus       { outline: 0; }
a[name]      { padding: 0; }
textarea     { overflow: auto; }
* html table { font-size: 100%; }

img, a img, object {
  border: 0;
  vertical-align: bottom;
}

.table thead th {
  border-top: 0;
}

abbr[title] {
  text-decoration: none;
}
