@include media-sm-only {
  #main {
    padding-bottom: 34rem; // larger spacing for footer
  }
}

@include media-md-only {
  #main {
    padding-bottom: 25rem; // spacing for footer
  }
}

@include media-lg-up {
  #main {
    padding-bottom: 22rem; // spacing for footer
  }
}

#bottom {
  border-top: 1px solid #eee;
  margin-top: spacer(3);
  background-color: $footer-bg;
  color: $footer-color;

  h3, h4 {
    color: $footer-color;
    font-family: $font-family-alt;
    font-weight: normal;
    background-color: $footer-bg;
    margin: spacer(5) 0 spacer(2) 0;
    @include text-truncate;
    line-height: 30px;

    a {
      color: $footer-color;
    }
  }

  @include media-sm-up {
    & {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }

  .nav-footer {
    min-height: 100px; // 4 rows text

    ul {
      @include clearfix;
      line-height: 24px;

      margin: 0 0 spacer(1) 0; // media reordering would move .text ul after this.
      list-style: none inside none;
      padding: 0;

      li {
        @include text-truncate;
      }

      a {
        color: $footer-color;

        &:hover {
          background-color: $footer-bg;
          color: $link-color;
        }
      }
    }

    li {
      margin: 0;
      padding: 0;
    }
  }
}

#media-nav {
  // Naming this #social-nav seems to trigger adblock/ghostery
  a {
    line-height: 24px;
    height: 24px;
  }

  .media-icons {
    padding-top: 0.2rem;
    text-align: right;
  }
}

.media-icons {
  svg {
    width: 32px;
    height: 32px;
    fill: $primary;
    vertical-align: middle;
  }
}

@include media-xs-down {
  #media-nav {
    // Place at last position
    order: 4;
  }
}

#final {
  clear: both;
  text-align: center;
  margin: spacer(5) 0 spacer(1) 0;

  .shared {
    display: inline-block;
    vertical-align: middle;

    @include media-sm-up {
      padding-right: spacer(1);
    }
  }

  .copyright {
    display: inline-block;
    vertical-align: middle;

    em {
      color: $primary;
      font-style: normal;
    }
  }
}
