#top-visual,
.jumbotron {
  background: $jumbotron-bg image-url('flowers-1280.jpg') no-repeat 0 -50px;

  .has-webp & {
    background-image: image-url('flowers-1280.webp');
  }

  // Background can expand, resize larger then the image is.
  // Note that the 'fixed' background always scales relative to the width/height of the window!
  background-size: cover;

  @include media-sm-only {
    background-position: 45% -50px;
    background-size: 135% auto; // keep the tree visible
  }
  @include media-xs-down {
    background-position: 45% 0;
    background-size: cover; // auto 100% in practice.
  }
}

.jumbotron {
  // Remaining styles are found in node_modules/bootstrap-sass/assets/stylesheets/bootstrap/_jumbotron.scss
  text-align: center;
  color: $jumbotron-color;
  border-radius: 0;
  margin-bottom: 3rem;

  @include font-smoothing(off);

  @include media-breakpoint-only(md) {
    padding: 4rem 1rem;
  }
  @include media-breakpoint-up(lg) {
    padding: 7rem 1rem 6rem;
  }

  .container {
    display: inline-block;
  }

  h1, h2, h3, h4 {
    color: $jumbotron-color;
    text-shadow: 0 1px 1px #999
  }

  h1 {
    font-family: $font-family-handwritten;
    margin-top: 1.2rem;
    font-size: 2.2rem;
  }

  p {
    font-size: 1.43rem;
    margin: 0 auto 1rem auto;
    font-weight: 300;
    text-shadow: 0 1px 1px #999
  }

  .btn {
    margin: spacer(1) 0.4rem;
    text-shadow: none;
    // disable .btn-lg from template
    @include button-size($btn-padding-y, $btn-padding-x, $font-size-base, $btn-line-height, $btn-border-radius);
  }

  @include media-sm-down {
    .container {
      max-width: none;
    }
  }

  @include media-sm-only {
    h1 {
      font-size: 2.87rem;
    }
  }

  @include media-md-only {
    h1 {
      font-size: 3.5rem;
    }
  }

  @include media-lg-up {
    h1 {
      font-size: 4.25rem;
    }

    p {
      max-width: 43.3rem
    }

    .btn {
      @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);
    }
  }
}

.jumbotron + .container > .row:first-child {
  margin-top: 5rem;
}
