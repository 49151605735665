//@import url(https://fonts.googleapis.com/css?family=Playball|Open+Sans);

body {
  // font-weight: 300;

  // Better font rendering on OS X.
  // Avoids sub-pixel antialiasing that makes font look bolder.
  @include font-smoothing(on);

  // closer numeric appearance (e.g. 2019)
  font-feature-settings: "pnum";
  font-variant-numeric: proportional-nums;
}

::selection {
  background: $brand-blue-dark;
  color: #fff
}


// Place any styles here that should only be used for textual content.
// The default styles come from: bootstrap/_type.scss
// Also consider overriding the variables, so bootstrap styles are rendered correctly.

/* ---- elements ---- */

a {
  transition: color 0.3s ease;
  outline: 0;

  &:focus,
  &:active {
    outline: 0;
  }
}


/* ---- plugins ---- */

h1, h2, h3 {
  margin-top: 1.4rem;
}

h3 {
  margin-top: 3rem;
}

.text {
  // Place any styles here that should only be used for the 'text' plugin.
  // The tinymce.scss will also pick up these styles.

  blockquote:not(.citation) {
    position: relative;
    border-left: 5px solid $brand-gray-lighter;
    padding: 0.5rem 1.5rem;

    p {
      margin: 0;
    }
  }

  h2 {
    font-family: $font-family-handwritten;
    color: $brand-green-dark;
  }

  h3, h4 {
    font-family: $font-family-alt;
    color: $brand-gray-darker;
    font-weight: normal;
    clear: both;
  }

  h4 {
    margin-top: .5rem;
  }

  // Table for picing info
  table {
// TODO    margin-bottom: ($line-height-computed / 2);

    th {
      text-align: left;
      padding-right: 10px;
    }
  }

  @include media-xs-down {
    ul, ol {
      padding-left: 26px;
      margin-left: 0;
    }
  }
}

.text img,
article img,
figure img {
  border-radius: 4px;
}

blockquote.citation {
  background: $review-bg;
  color: $review-color;
  padding: 1.5em 1.4em 1.5em 3.5em;
  border-radius: ($border-radius-lg * 4);
  font-size: 1.09rem;
  position: relative;

  h3 {
    margin-top: 0;
    color: $review-color;
  }

  @include media-sm-up {
    margin: 1.5em 0 1.5em 0;

    article & {
      margin-left: 2em; // blog article
    }
  }

  &::before {
    position: absolute;
    left: 1.6rem;
    top: 1.7rem;

    color: $review-quote;
    content: "\201C";
    font-family: Georgia, serif;
    font-size: 4em;
    line-height: 1;
  }

  small a {
    color: $review-color;
  }
}

iframe {
  border: 1px solid #ccc;
  box-sizing: border-box;
}
