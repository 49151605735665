.cta {
  // Basically variation of "media"
  margin: 1rem 0 2rem 0;
  font-size: 120%;

  .band + & {
    margin-top: 5rem;
  }

  h2, h3 {
    margin-top: 0;
  }

  @include media-md-up {
    align-items: center;
    display: flex;
  }

  &.cta-info-bubble {
    padding: 2rem 3rem;
    border-radius: $card-border-radius;
    background-color: $card-bg;
    border: 1px solid $card-border-color;
    color: $card-color;
  }
}

.cta-text {
  flex: 1;

  @include media-md-up {
    max-width: 26rem;  // avoid long text lines
    margin-right: 3rem;  // Space between for button

    p:last-child {
      margin-bottom: 0;  // for button alignment
    }
  }
}

.cta-button {
  margin-left: auto;  // push button to the right
}

.btn-arrow-right {
  border-radius: 1rem;
  border-width: 2px;
  padding-left: 1rem;
  padding-right: 1rem;
  vertical-align: middle;
  position: relative;

  span {
    display: inline-block;
    padding: 0 1rem 0 1rem;
    transition: padding 300ms ease-in-out;
  }

  i {
    position: absolute;
    right: 1rem;
    top: 0;
    bottom: 0;
    width: 2rem;
    line-height: 210%;
    font-style: normal;

    text-align: right;
    vertical-align: middle;
    overflow: hidden;
    opacity: 0;
    transition: opacity 300ms ease-in-out;
    color: $white;
  }

  &:active,
  &:focus,
  &:hover {
    span {
      padding: 0 1.8rem 0 .2rem;
    }

    i {
      opacity: 1;
    }
  }

  @include button-outline-variant($info);
}
