

.academic-text {
  .cite-link.active {
    // In text anchor
    background-color: $cite-bg;
  }

  .cite-source.active {
    // In reference list
    background-color: $cite-bg;
    border-radius: 4px;
  }

  .cite-backlinks {
    // Backlinks in the reference list
    vertical-align: super;
    font-size: .83em;
    color: $link-color;

    span, a {
      font-weight: bold;
    }

    a {
      font-style: italic;
    }
  }
}

.popover-academic-text,
.bs-popover-top {
  min-width: 35em;
  max-width: 30%;
  color: $body-color;
  margin-left: -6px; // workaround bs5/bs4 mix.
}
