.pager {
  // If there is overlapping effect, avoid touching each other.
  @include list-unstyled();
  display: flex;
  flex-direction: row;
  margin-bottom: 0;

  .page-link {
    position: relative;
    border-radius: 15px !important;

    span {
      position: absolute;
      top: 0.5rem;
    }
  }

  .previous {
    align-self: flex-start;

    .page-link {
      padding-left: 2rem;
    }

    span {
      left: 0.75rem;
    }
  }

  .next {
    align-self: flex-end;
    margin-left: auto;

    .page-link {
      padding-right: 2rem;
    }

    span {
      right: 0.75rem;
    }
  }

  li a {
    // TODO: margin-bottom: $line-height-computed;
  }
}

@include media-md-down {
  // Place below each other on very small screens
  .pager {
    flex-direction: column;

    .page-item {
      margin-bottom: 1rem;
    }
  }
}
