
#back-top-wrapper {
  position: fixed;
  right: 10px;
  bottom: 10px;
  overflow: hidden;
  z-index: 999;
}

#back-top {
  a {
    display: block;
    width: 50px;
    height: 50px;

    color: #bbb;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    font: 11px/100% Arial, Helvetica, sans-serif;
    transition: 1s;

    &:hover {
      color: #000;

      span {
        background-color: #31323a;
      }
    }
  }

  span {
    display: block;
    margin-bottom: 7px;
    width: 100%;
    height: 100%;

    background: $primary image-url('up-arrow.png') no-repeat center center;
    border-radius: 50%;
    transition: 1s;

    .has-webp & {
      background-image: image-url('up-arrow.webp');
    }
  }
}
