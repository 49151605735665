.btn {
  transition: color 0.3s ease-out,
              background-color 0.3s ease-out;
  @include font-smoothing(off);
}

.btn.pull-right {
  // Signup button for workshop
  position: relative;
  z-index: 1;
}


.alert {
  ul, li { margin: 0; padding: 0; list-style: none; }
}
