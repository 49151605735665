.band {
  $padding-top: spacer(3);
  $padding-bottom: spacer(4);

  position: relative;
  clear: both;
  background-color: $band-bg;
  // Coping with non-collapsing margins here, caused by titles and row wrapping.
  padding: $padding-top 0 $padding-bottom;
  margin: 3.5rem 0 1.5rem 0; // alt: spacer(5) 0 spacer(4) 0;

  &:after,
  &:before {
    background-color: $band-bg;
    content: "";
    position: absolute;
    top: 0;
    width: 101%;
    height: 100%;
    z-index: -1;
  }

  &:after {
    right: -99%;
  }

  &:before {
    left: -99%;
  }

  h2 {
    font-family: $font-family-alt;
    color: $brand-gray-darker;
    font-weight: normal;
    clear: both;

    margin-bottom: spacer(2);
  }

  .text blockquote:not(.citation) {
    border-left: 0;
  }

  blockquote.citation {
    background-color: inherit;
    margin: 1.5em 0 2em 0;
    padding: 0 0 0 2.5em; // still keep the indent for the “ sign
    border: 0;

    &::before {
      left: 0.1rem;
      top: 0.1rem;
    }

    @include media-sm-up {
      margin-right: 4em;
    }
  }

  &.lighter {
    background-color: $band-lighter-bg;
    color: $body-color;

    &:before,
    &:after {
      background-color: $band-lighter-bg;
    }
  }

  // Swirl shape
  > svg {
    display: block;
    position: relative;
    // Center and full width.
    left: 50%;
    z-index: 0;

    path {
      //fill: #fff;
    }
  }

  > svg:first-child {
    transform: translateX(-50%);  // moves to left
    top: -$padding-top;  // padding of band.
    width: 150vw;
    height: 20px;
  }

  > svg:last-child {
    transform: translateX(-50%) rotate(180deg);  // move to left AND upside down
    width: 200vw;
    height: 20px;
    bottom: -$padding-bottom;  // padding of band.
  }
}
