// Variations can be generated with https://everythingfonts.com/font-face or https://transfonter.org/

@font-face {
    // Main title
    font-family: 'Playball';
    src: /* local('Playball'), local('Playball-Regular') , */
      font-url('Playball-regular.woff2') format('woff2'),
      font-url('Playball-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;  // titles
}

@font-face {
    // Secondary titles
    font-family: 'Open Sans';
    src: local('Open Sans'), local('OpenSans'),
      font-url('OpenSans.woff2') format('woff2'),
      font-url('OpenSans.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;  // titles only
}

@font-face {
    // Jumbotron, breadcrumb
    font-family: 'Open Sans';
    src: local('Open Sans Light'), local('OpenSansLight'),
      font-url('OpenSans-300.woff2') format('woff2'),
      font-url('OpenSans-300.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: block;
}

@font-face {
    // Headers
    font-family: 'Open Sans';
    src: local('Open Sans Medium'), local('OpenSansMedium'),
      font-url('OpenSans-500.woff2') format('woff2'),
      font-url('OpenSans-500.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: block;
}

@font-face {
    // Text
    font-family: 'Open Sans';
    src: local('Open Sans Bold'), local('OpenSansBold'),
      font-url('OpenSans-700.woff2') format('woff2'),
      font-url('OpenSans-700.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: block;
}

@font-face {
    // YouTube titles
    font-family: 'Roboto';
    src: local('Roboto'),
      font-url('Roboto.woff2') format('woff2'),
      font-url('Roboto.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;  // no rush
}

@font-face {
    // YouTube titles
    font-family: 'Roboto';
    src: local('Roboto Medium'), local('RobotoMedium'),
      font-url('Roboto-500.woff2') format('woff2'),
      font-url('Roboto-500.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;  // no rush
}
