// Debugging for media queries
#media-debug {
  position: fixed;
  left: 0;
  bottom: 20px;
  color: #000;
  font-weight: bold;
  padding: 2px 5px;
  z-index: 1001;
}

#media-debug { background-color: #00f000; }
#media-debug::after { content: "XS-Mobile (XS)"; }

@include media-breakpoint-only(sm) {
  #media-debug { background-color: #00d000; }
  #media-debug::after { content: "Mobile (SM)"; }
}

@include media-breakpoint-only(md) {
  #media-debug { background-color: #00b100; }
  #media-debug::after { content: "Tablet (MD)"; }
}

@include media-breakpoint-only(lg) {
  #media-debug { background-color: green; }
  #media-debug::after { content: "Desktop (LG)"; }
}

@include media-breakpoint-only(xl) {
  #media-debug { background-color: green; }
  #media-debug::after { content: "Jumbo (XL)"; }
}
