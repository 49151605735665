form.comments-form,
.contactform-compact {

  // Smaller margin for top column,
  // to be the same as the 15px margin
  .row {
    margin-left: -7px;
    margin-right: -8px;
  }

  .formColumn {
    padding-left: 7px;
    padding-right: 8px;
  }
}

.contactform-compact {
  input[type=url] {
    display: none;
  }

  .form-group[id$=-website] {
    display: none;
  }
}

form {
  .form-control {
    box-shadow: none;

    &:focus {
      box-shadow: none;
    }
  }
}
