#focus {
  background: #000 image-url('flowers-1280.jpg') fixed no-repeat center top;
  background-size: cover;

  h2 {
    text-align: center;
  }

  h3 {
    margin-top: spacer(4);
  }

  footer {
    border-top: 0;
  }

  #lang-toggle {
    position: fixed;
  }

  #main {
    padding-bottom: 14rem;
  }

  #main-content {
    // Mollie inspired
    margin: 0 auto;
    position: relative;
    width: 100%;
    max-width: 520px;
    overflow: hidden;
    padding: 40px 15px 0;
    background: #fafafa;

    @media only screen and (min-width: 414px) {
      padding: 50px 30px 0;
    }
    @media only screen and (min-width: 522px) {
      border-radius: 12px;
    }
    @media only screen and (min-width: 600px) {
      width: calc(100% - 80px);
      margin: 40px auto 0;
      padding: 50px;
      box-shadow: 0 0 20px rgba(0, 0, 0, .05), 0 20px 40px rgba(0, 0, 0, .05);
    }
  }
}
