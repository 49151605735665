/* ---- blog archive ---- */

@include media-sm-only { .clear-sm { clear: left; } }
@include media-md-only { .clear-md { clear: left; } }
@include media-lg-up { .clear-lg { clear: left; } }

#blog {
  .title-over-image > a {
    @include media-sm-only { min-height: 302px; }
    @include media-md-only { min-height: 209px; }
    @include media-lg-only { min-height: 285px; }
    @include media-xl-only { min-height: 222px; }
  }
}


/* ---- blog detail ---- */

article {
  h1, h2 {
    // Improve heading style for blog
    a {
      color: $headings-color;
      text-decoration: none;

      &:hover {
        color: $headings-color;
      }
    }
  }

  .entry-excerpt {
    picture img {
      margin-bottom: spacer(1);
    }
  }

  figure a {
    // for /blog/2016/08/reiki-for-the-health-of-it/
    display: inline-block;
    position: relative;
    border-radius: 4px;
    overflow: hidden;

    img {
      transition: transform 0.2s $ease-in-out-quad;
    }

    &:hover img {
      transform: perspective(1px) scale(1.03);
    }
  }
}

@include media-breakpoint-only(lg) {
  // xl uses offset, <=md flows below content
  aside .recent-entries {
    margin-left: 1rem;
  }
}
