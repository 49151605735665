figure.picture {
  // Picture plugin in the CMS
  margin: spacer(3) 0 spacer(3) 0;

  figcaption {
    text-align: center;
    color: $brand-gray-dark;
    font-family: $font-family-alt;
    //TODO:font-size: ($font-size-base + 1px);
    margin: spacer(3) 0;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  &.align-left {
    clear: left;
    float: left;
    margin: 0 1em 1em 0;
  }

  &.align-right {
    clear: right;
    float: right;
    margin: 0 0 1em 1em;
  }

  &.align-center {
    text-align: center;
  }

  + figure.picture {
    // Second image, place in row.
    &.align-left,
    &.align-right {
      clear: none;
    }
  }
}

#homepage figure.picture {
  text-align: center;
}
