// Bootstrap 3 compatibility for plugins that generate Bootstrap 3 CSS selectors

.btn-default {
  @extend .btn-light;
}

blockquote {
  @extend .blockquote;
}

blockquote small {
  @extend .blockquote-footer
}

.form-horizontal .form-group {
  @extend .row;
}

.container {
  flex: 1; // contains margins!
}

.pull-right {
  display: table;
  margin-left: auto;
}

//
// Wells (Customized version)
//

// Base class
.well {
  @extend .card;
  @extend .card-body;

  //
  //min-height: 20px;
  //padding: 19px;
  //margin-bottom: 20px;
  //border: 1px solid $card-border-width;
  //border-radius: $card-border-radius;
  //
  h2, h3 {
    margin-top: 0;
  }

  ul, ol {
    padding-left: 1.5rem;
    margin-left: 0;
  }

  > .text p:last-child {
    margin-bottom: 0;
  }

  //@include media-xs-down {
  //  padding: 9px;
  //  margin-top: 20px;
  //}
}
