// All variables that are used throughout the CSS.
//
// This file also overrides Bootstrap variables.
// For a complete overview of all variables to override, see:
// * node_modules/bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss
// * https://github.com/twbs/bootstrap/blob/v4.6.1/scss/_variables.scss

// mixins (using webpack root)
$image-url-path: 'frontend/images/';
$fonts-url-path: 'frontend/fonts/';

// Base settings
$font-family-base: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-handwritten: "Playball", cursive;
$font-family-alt: $font-family-base;  // previously exception for "Open Sans".
$font-size-base: 1rem; // size of 1rem is overwritten in html {..}
$line-height-base: 1.7;

$h2-font-size: 2.2rem;

// Style guide
$brand-green: #0aab8a;
$brand-green-dark: #30696f;
$brand-gray-darker: #474e5d;
$brand-gray-dark: #999;
$brand-gray: #ddd;
$brand-gray-light: #e5e5e5;
$brand-gray-lighter: #f1f1f1;
$brand-gray-lightest: #f5f5f5;

$brand-blue-darkest: #526379;
$brand-blue-darker: #6090be;
$brand-blue-dark: #7db5e9;
$brand-blue: #b9d5ef;
$brand-blue-light: #deeeff;
$brand-blue-lighter: #e3f7fe;

// Base colors
$body-color: #606573;
$primary: $brand-green;
$secondary: $brand-gray-darker;
$headings-color: $brand-green-dark;
$info: $brand-blue-darker;

// Footer
$footer-bg: #343746;
$footer-color: #ddd;

// Custom
$cite-bg: $brand-blue;
$review-bg: $brand-blue-light;
$review-color: $brand-blue-darkest;
$review-quote: $brand-blue;
$blockquote-small-color: $brand-blue-darker;
$band-bg: $brand-blue-light;
$band-lighter-bg: $brand-blue-lighter;
$ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);

// Menu
$logo-color: #fff;
$logo-bg: $primary;
$logo-hover-color: #fff;

// Navbar
$navbar-height: 3rem;  // mobile version.

$navbar-light-color: $secondary;
$navbar-light-hover-color: $brand-green-dark;
$navbar-light-active-color: $primary;

$navbar-padding-x: 0;
$navbar-padding-y: 0;

// Overlap
$navbar-height-md: 5rem;

// Headings
$headings-margin-bottom: 0.7rem;

// Card (Well)
$card-color: $brand-blue-darkest;
$card-bg: $brand-blue-light;
$card-border-color: $brand-blue-lighter;
$card-border-radius: 1rem;

// Jumbotron
$jumbotron-bg: #efdc90;  // visible while loading on mobile
$jumbotron-color: #fff;
$jumbotron-padding: 1.5rem;  // is 3rem in boostrap sass for mobile

// Links
$link-color: $primary;
$link-hover-color: $secondary;
$link-hover-decoration: none;

// forms
$input-border: $brand-gray-light;
$input-border-focus: $brand-gray;
$input-bg: $brand-gray-lighter;
$input-color: $body-color;
$input-color-placeholder: $body-color;

// Breadcrumb
$breadcrumb-bg: transparent;
$breadcrumb-padding-y: 0.4rem;
$breadcrumb-color: #fff;
$breadcrumb-active-color: $brand-green;

// Tables
$table-cell-padding-sm: 0.4rem;

$navbar-light-toggler-icon-bg: none;
$navbar-dark-toggler-icon-bg: none;
