#blog .teaser {
  h4 {
    font-size: $font-size-lg;
  }

  @include media-breakpoint-only(md) {
    h4 { font-size: ($font-size-base * 1.18); }
    p { font-size: ($font-size-base * 0.9); }
  }

  @include media-breakpoint-up(xl) {
    h4 { font-size: ($font-size-base * 1.2); }
    p { font-size: ($font-size-base * 0.95); }
  }
}

.teaser {
  &:hover img {
    transform: perspective(1px) scale(1.03);
  }
}

header.title-over-image {
  position: relative;
  margin-bottom: spacer(4);

  // h3 = homepage
  // h4 = blog, teaser
  h3, h4 {
    padding: 0;
    margin: 0;
    font-size: $font-size-lg;
  }

  @include media-md-only {
    h3 {
      font-size: $font-size-base;  // homepage
    }
  }

  @include media-breakpoint-up(xl) {
    .teaser & h4 { font-size: ($font-size-base * 1.2); }
  }

  > h3,
  > h4,
  > .title {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    color: #fff;
    background-color: rgba(30, 30, 30, 0.8);
    z-index: 2;

    a {
      display: block;
      line-height: 1;
      color: #fff;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  > h3 a,
  > h4 a {
    // TODO: padding: ($padding-large-vertical * 1.5) 10px;
    padding: spacer(3) 10px;
  }

  .title a {
    padding: 0.6rem 0.6rem 0.3rem 0.6rem;
  }

  > a {
    display: block; // to support containing an <picture>
    overflow: hidden;
    border-radius: 4px 4px 0 0;
    position: relative;
  }

  img {
    max-width: 100%;
    height: auto;
    transition: transform 0.2s $ease-in-out-quad;
  }

  a > p {
    margin-bottom: 0.5rem;
  }
}
