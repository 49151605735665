$avatar-width: 64px;
$avatar-margin: spacer(3);

.comment-item {
  img.user-image {
    border-radius: 50%;
    margin-right: spacer(3);
    max-width: $avatar-width !important;  // 128px original
    height: auto !important;
  }

  h4 {
    font-size: $font-size-base;
    border-bottom: 1px solid $brand-gray-light;
    padding: spacer(2);
  }

  .comment-text,
  .comment-tools {
    margin: spacer(2);
  }

  .comment-tools {
    display: flex;
    justify-content: space-between;
    line-height: $font-size-base;
  }

  .comment-reply-link {
    align-self: flex-start;
  }

  .comment-date {
    align-self: flex-end;
    font-size: $font-size-sm;
  }

  &.by-author {
    .media-body {
      background-color: $brand-gray-lighter;
    }
  }

  form {
    margin-top: spacer(3);
    margin-left: $avatar-width;
    padding-left: $avatar-margin;
  }
}

#comment-preview {
  margin-top: spacer(3);
  padding: spacer(2);
  background-color: $brand-gray-lightest;
  border-radius: 1rem;

  .media {
    padding-left: 0.5rem;
  }

  h3 {
    font-size: $font-size-lg;
    margin-top: 0.3rem;
  }
}

#comments-wrapper {
  > h3 {
    margin: spacer(5) 0 spacer(4);
  }

  ul.comment-list-wrapper ul.comment-list-wrapper {
    margin: spacer(3) 0 spacer(1) calc($avatar-width / 2);
    padding-left: $avatar-margin;
  }
}

// --- contents of ajaxcomments.css, inlined:

#comment-waiting {
  line-height: 16px;
}

#comment-waiting img {
  vertical-align: middle;
  padding: 0 4px 0 10px;
}

#comment-added-message,
#comment-thanks {
  padding-left: 10px;
}

.comment-moderated-flag {
  font-variant: small-caps;
  margin-left: 5px;
}

#div_id_honeypot {
  /* Hide the honeypot from django_comments by default */
  display: none;
}


/* ---- threaded comments ---- */

ul.comment-list-wrapper {
  /* to avoid touching our own "ul" tags, our tags are explicitly decorated with a class selector */
  margin-left: 0;
  padding-left: 0;
}

ul.comment-list-wrapper ul.comment-list-wrapper {
  margin-left: 1em;
  padding-left: 0;
}

li.comment-wrapper {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

.js-comments-form-orig-position .comment-cancel-reply-link {
  display: none;
}
