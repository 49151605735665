@include media-xs-down {
  .text img[src*='/behandelaar/'] {
    // full width on tiny mobile screens (portrait mode)
    display: block;
    float: none !important;
    width: auto !important;
    max-width: 100% !important;
    height: auto !important;
    margin: 0 !important;
  }
}

@include media-md-down {
  .text img[src*='/behandelaar/diederik-side'] {
    // full width on large mobile screens (portrait mode)
    display: block;
    float: none !important;
    width: auto !important;
    max-width: 100% !important;
    height: auto !important;
    margin: 0 !important;
  }
}

.iframe16x9 {
  overflow: hidden;
  padding-top: 56.25%; // 16:9 aspect ratio
  position: relative;
}

.iframe16x9 iframe {
   position: absolute;
   border: 0;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;
}
