.image-with-text {
  &.row {
    // When used in column layout
    clear: both;

    figure.picture {
      @include media-breakpoint-up(md) {
        margin-top: 0.5rem; // row contains, reduce top-margin
      }

      img {
        // img-fluid for column based versions only (and non-floating breakpoints below).
        @include img-fluid();
      }
    }
  }

  &.floating {
    // When used in floating layout
    @include clearfix();

    figure.picture {
      margin-top: 0.5rem;
    }
  }
}

.image-part {  // figure element
  max-width: 100%;  // float floating, avoid extending outside

  // Margins for floating variant
  &.float-start {
    margin-right: 1.5rem;  // extra margin right, for text
    margin-bottom: 0.5rem;
    float: left;
  }

  &.float-end {
    margin-left: 1.5rem;  // extra margin left, for text
    margin-bottom: 0.5rem;
    float: right;
  }

  @include media-breakpoint-down(md) {
    // full width in responsive mobile
    &.float-md-end img {
      @include img-fluid;
    }
  }

  @include media-breakpoint-up(md) {
    &.float-md-end {
      float: right;
      margin: 0.75rem 0 0.5rem $grid-gutter-width;  // extra margin left, reduce top/bottom.
    }
  }

  @include media-breakpoint-down(lg) {
    &.float-lg-end img {
      // full width in responsive mobile
      @include img-fluid;
    }
  }

  @include media-breakpoint-up(md) {
    &.float-lg-end {
      float: right;
      margin: 0.75rem $grid-gutter-width 0.5rem 0;  // extra margin right, reduce top/bottom.
    }
  }
}

.text-part {
  min-height: 100%; // full col
  contain: style;

  @include media-breakpoint-up(md) {
    h3 {
      margin-top: 0.5rem; // smaller margin when aside of content
    }
  }

  /*
  @include media-breakpoint-up(lg) {
    // Larger margins on desktop
    .imagetext-align-image-left & {
      margin: 0 0 2rem 3rem;
    }

    .imagetext-align-image-right & {
      margin: 0 3rem 2em 0;
    }
  }
  */

  .btn {
    margin: 0.5rem 0 0.5rem 0; // extra space at bottom
  }
}

@include media-breakpoint-down(md) { // TODO: down grid name incorrect?
  .reorder-sm-first {
    order: -1; // order- classes are removed
  }
}

@include media-breakpoint-down(lg) {
  .reorder-md-first {
    order: -1; // order- classes are removed
  }
}
