#top-visual {
  // the top row on other pages (not the homepage)
  // The background is defined in _jumbotron.scss
  color: #fff;
  position: relative;
  padding-top: 2.5rem;  // Space above breadcrumb

  .top-visual-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(49, 53, 61, 0.1);
  }

  .top-location {
    // The GRAY area
    position: relative;  // on top of overlay
    background-color: rgba(49, 53, 61, 0.9);
    padding: 1rem 3rem;
  }

  h1 {
    font-weight: 300;
    margin: 0;
    font-size: 30px;
    line-height: 40px;
    font-family: $font-family-alt;
    display: none;
    color: #fff;

    #homepage & {
      display: block;
    }
  }

  .breadcrumb {
    text-transform: uppercase;
    font: 300 1rem/1.25rem $font-family-alt;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    overflow: hidden;

    // Also apply to links
    a {
      color: $breadcrumb-color;

      &:hover,
      &:active {
        color: $breadcrumb-active-color;
      }
    }

    li.active a {
      color: $breadcrumb-active-color;
    }

    > li + li:before {
      content: "|";
      padding: 0 1.25rem;
    }
  }

  @include media-sm-down {
    .top-location {
      padding: 10px 30px 10px 30px;
    }

    h1 {
      font-size: 18px;
      line-height: 24px;
      padding: 0 0 5px 0;
    }

    .breadcrumb {
      > li + li:before {
        padding: 0 5px;
      }
    }
  }
}

#top-visual + #main-content {
  padding-top: 1.25rem;  // space to top-visual
}
