.payment-methods-form {
  max-width: 400px;
  margin: 1rem auto;

  .footer {
    margin-top: 1rem;
  }

  .invalid-feedback {
    padding-left: 1rem;  // align closer to icons
    font-weight: bold;
  }
}

.payment-methods {
  input {
    display: none;
  }

  input:checked {
    // Using sibling selectors, because label:has(input:checked) doesn't work in Firefox.
    + label {
      background-color: $brand-gray-light;
      border-color: $primary;
      color: $primary;
    }
  }

  label {
    display: block;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s $ease-in-out-quad;
    padding: 0.5rem 0.5rem;
    margin: 0;

    svg, span {
      vertical-align: middle;
      display: inline-block;
    }

    &:hover {
      background-color: $brand-gray-lightest;
    }

    span {
      padding-left: .5rem;
    }
  }
}

.payment-method {
  border: 1px solid #dde;
  border-radius: 4px;
  margin: .2rem 0;
  overflow: hidden;  // for border radius
}

.payment-method[aria-hidden="true"] { display: none; }
