// --- stretch page by default

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth; // Smooth anchor scrolling!
  font-size: 17px;  // bump every rem while keeping the design ratio
}

@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto; // Jump scrolling if people configured that
  }
}

#wrapper {
  min-height: 100%;
  position: relative;
}

#main {
  position: relative;
  overflow: hidden;  // for .band
}

#homepage {
  .constrained > .container {
    max-width: map-get($container-max-widths, lg);
  }
}

#page403, #page404, #page500 {
  #main-content {
    text-align: center;
  }
}

#page500 {
  #main {
    padding-bottom: 2rem;  // reset from _footer.scss
  }

  #header {
    flex-direction: column;
  }

  .navbar-logo {
    align-self: center;
  }
}

@include media-breakpoint-down(sm) {
  .hide-sm { display: none; }
}
@include media-breakpoint-down(md) {
  .hide-md { display: none; }
}
@include media-breakpoint-up(md) {
  .hide-md-up { display: none; }
}
@include media-breakpoint-up(lg) {
  .hide-lg-up { display: none; }
}
