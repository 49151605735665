.recent-entries-section {
  margin: spacer(3) 0;

  .recent-entries-item {
    margin: spacer(1) 0 spacer(3) 0;

    .title {
      margin: spacer(2) 0 spacer(1) 0;
    }
  }

  .col-md-reset {
    clear: left;
  }
}

.more-news-link {
  text-align: right;
  position: relative;
  top: -$font-size-base;
}

.recent-entries {
  margin-bottom: 10px;
  position: relative;

  h2 {
    // For section variant
    font-family: $font-family-handwritten;
    color: $brand-green-dark;
  }

  h3 {
    // Widget variant
    font-weight: normal;
    font-size: ($font-size-base * 1.3);
    vertical-align: top;
    margin-top: 0;
  }
}
.recent-entries-item {
  position: relative;
  margin-bottom: 10px;

  a.img-link {
    display: block;  // keep zoom inside (if there is no float)
    position: relative;
    overflow: hidden;
    border-radius: 4px;
  }

  img {
    transition: transform 0.2s ease-out;
  }
  a {
    transition: color 0.2s ease-out;
  }

  &:hover {
    img {
      transform: perspective(1px) scale(1.04);
    }
    .title a {
      color: $brand-green-dark;
    }
  }

  .title {
    font-weight: bold;
    font-size: .95rem;  // reset to old sizes
    line-height: 1.4;

    a {
      color: $brand-gray-darker;
    }
  }
}

.recent-entries-widget {
  .recent-entries-item {
    display: flex;
    align-items: center;

    a.img-link {
      float: left;
      margin-right: 20px;
      min-width: 100px;
      width: 100px;
    }

    .title {
      margin: 0;
      max-height: 70px;
      overflow: hidden;
    }
  }
}
